import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import append from '@nubank/nuds-web/utils/string/append';
import prepend from '@nubank/nuds-web/utils/string/prepend';
import removeSpaces from '@nubank/nuds-web/utils/string/removeSpaces';
import compose from '@nubank/nuds-web/utils/functional/compose';
import Icon from '@nubank/nuds-web/components/Icon/Icon';
import Link from '@nubank/nuds-web/components/Link/Link';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import Footer from '@nubank/nuds-web/patterns/Footer/Footer';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { localizeRoute, maybeFormatPrefixedMessage } from 'utils/i18nUtils';
import AppStoreBadge from 'components/AppStoreBadge/AppStoreBadge';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

import { StyledLink } from './styles/StyledLink';
import { StyledAppLink } from './styles/StyledAppLink';
import { StyledBox } from './styles/Box';

const formatNubankSocialLink = (intl, intlSufix, sufix) => compose(
  append(sufix),
  maybeFormatPrefixedMessage(intl, 'NUBANK.SOCIAL'),
)(intlSufix);

const formatNubankContact = (intl, prefix, intlSufix) => compose(
  prepend(prefix),
  removeSpaces,
  maybeFormatPrefixedMessage(intl, 'NUBANK.CONTACT'),
)(intlSufix);

function FooterBrazil({
  navigation,
  company,
  insurance,
  languageSwitcher,
}) {
  const intl = useIntl();
  const { pathname } = useRouter();

  const { locale, formatMessage } = intl;

  return (
    <Footer>
      {navigation && (
      <Footer.Navigation data-testid="footer-navigation">
        {({
          Column,
          ColumnTitle,
          ColumnText,
          ColumnLink,
        }) => (
          <>
            <Column>
              <ColumnTitle intlKey="FOOTER.NUBANK.TITLE" />
              <ColumnLink
                href="/sobre-nos"
                displayedPath={localizeRoute('/sobre-nos', locale)}
                intlKey="FOOTER.NUBANK.ABOUT_US"
              />
              <ColumnLink
                href="/carreiras"
                displayedPath={localizeRoute('/carreiras', locale)}
                intlKey="FOOTER.NUBANK.CAREERS"
              />
              <ColumnLink
                href="/perguntas"
                displayedPath={localizeRoute('/perguntas', locale)}
                intlKey="FOOTER.NUBANK.FAQ"
              />
              <ColumnLink
                href="/contato"
                displayedPath={localizeRoute('/contato', locale)}
                intlKey="FOOTER.NUBANK.CONTACT"
              />
              <ColumnLink
                href="/imprensa"
                displayedPath={localizeRoute('/imprensa', locale)}
                intlKey="FOOTER.NUBANK.PRESS"
              />
              <ColumnLink
                variant="action"
                href={formatMessage({ id: 'ESG.EXTERNAL_URL' })}
                intlKey="FOOTER.NUBANK.ESG"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.PRODUCTS.TITLE" />
              <ColumnLink
                href="/conta"
                displayedPath={localizeRoute('/conta', locale)}
                intlKey="FOOTER.PRODUCTS.NUCONTA"
              />
              <ColumnLink
                href="/cartao"
                displayedPath={localizeRoute('/cartao', locale)}
                intlKey="FOOTER.PRODUCTS.CREDIT_CARD"
              />
              <ColumnLink
                href="/ultravioleta"
                displayedPath={localizeRoute('/ultravioleta', locale)}
                intlKey="FOOTER.PRODUCTS.ULTRAVIOLET"
              />
              <ColumnLink
                href="/emprestimo"
                displayedPath={localizeRoute('/emprestimo', locale)}
                intlKey="FOOTER.PRODUCTS.LENDING"
              />
              <ColumnLink
                href="/conta-pj"
                displayedPath={localizeRoute('/conta-pj', locale)}
                intlKey="FOOTER.PRODUCTS.BUSINESS_ACCOUNT"
              />
              <ColumnLink
                href="/cartao-pj"
                displayedPath={localizeRoute('/cartao-pj', locale)}
                intlKey="FOOTER.PRODUCTS.BUSINESS_CREDIT_CARD"
              />
              <ColumnLink
                href="/seguros"
                displayedPath={localizeRoute('/seguros', locale)}
                intlKey="FOOTER.PRODUCTS.INSURANCE"
              />
              <ColumnLink
                href="/investimentos"
                displayedPath={localizeRoute('/investimentos', locale)}
                intlKey="FOOTER.PRODUCTS.INVESTMENTS"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.EXPLORE.TITLE" />
              <ColumnLink
                variant="action"
                href={formatNubankSocialLink(intl, 'NUCOMMUNITY', '?utm_content=www_hero')}
                intlKey="FOOTER.EXPLORE.NUCOMMUNITY"
              />
              <ColumnLink
                variant="action"
                href={formatNubankSocialLink(intl, 'BLOG', '')}
                intlKey="FOOTER.EXPLORE.BLOG"
              />
              <ColumnLink
                variant="action"
                href={formatNubankSocialLink(intl, 'INVEST_NEWS', '')}
                intlKey="FOOTER.EXPLORE.INVEST_NEWS"
              />
              <ColumnLink
                variant="action"
                href={formatNubankSocialLink(intl, 'BLOG', 'assinar-newsletter/?utm_content=www_footer-nlcta')}
                intlKey="FOOTER.EXPLORE.NEWSLETTER"
              />
              <ColumnLink
                variant="action"
                href="https://nu.com.mx"
                intlKey="FOOTER.EXPLORE.MX"
              />
              <ColumnLink
                variant="action"
                href="https://nu.com.ar"
                intlKey="FOOTER.EXPLORE.AR"
              />
              <ColumnLink
                variant="action"
                href="https://nu.com.co"
                intlKey="FOOTER.EXPLORE.CO"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.LEARN_MORE.TITLE" />
              <ColumnLink
                forceRefresh
                variant="action"
                href="/auxilio-emergencial-fgts"
                intlKey="FOOTER.LEARN_MORE.FGTS"
              />
              <ColumnLink
                variant="action"
                href="/pix"
                intlKey="FOOTER.LEARN_MORE.PIX"
                forceRefresh
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.TRANSPARENCY.TITLE" />
              <ColumnLink
                forceRefresh
                href="/contrato/politica-privacidade"
                intlKey="FOOTER.TRANSPARENCY.PRIVACY_POLICY"
              />
              <ColumnLink
                forceRefresh
                href="/contrato/politica-de-compliance"
                intlKey="FOOTER.TRANSPARENCY.COMPLIANCE_POLICY"
              />
              <ColumnLink
                href="/contrato"
                intlKey="FOOTER.TRANSPARENCY.CONTRACTS"
              />
              <ColumnLink
                forceRefresh
                href="/contrato/politica-seguranca"
                intlKey="FOOTER.TRANSPARENCY.SECURITY_POLICY"
              />
              <ColumnLink
                href="/relatorios-financeiros"
                intlKey="FOOTER.TRANSPARENCY.FINANCIAL_STATEMENTS"
              />
              <ColumnLink
                href="/etica-e-compliance"
                intlKey="FOOTER.TRANSPARENCY.ETHICS"
              />
              <ColumnLink
                href="/dados-abertos"
                displayedPath={localizeRoute('/dados-abertos', locale)}
                intlKey="FOOTER.TRANSPARENCY.OPEN_DATA"
              />
              <ColumnLink
                href="/scr"
                displayedPath={localizeRoute('/scr', locale)}
                intlKey="FOOTER.TRANSPARENCY.SCR"
              />
              <ColumnLink
                href="https://cdn.nubank.com.br/convencao-entre-instituicoes-sistema-financeiro-nacional.pdf"
                intlKey="FOOTER.TRANSPARENCY.BOLETOS"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.OMBUDSMAN.TITLE" />
              <ColumnLink
                href={formatNubankContact(intl, 'tel:', 'OMBUDSMAN_PHONE')}
                intlKey="NUBANK.CONTACT.OMBUDSMAN_PHONE"
              />
              <ColumnLink
                href={formatNubankContact(intl, 'mailto:', 'OMBUDSMAN_EMAIL')}
                intlKey="NUBANK.CONTACT.OMBUDSMAN_EMAIL"
              />
              <ColumnText
                color="white"
                colorVariant="dark"
                intlKey="FOOTER.OMBUDSMAN.BUSINESS_HOURS"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.CONTACT.TITLE" />
              <ColumnLink
                href={formatNubankContact(intl, 'tel:', 'PHONE')}
                intlKey="NUBANK.CONTACT.PHONE"
              />
              <ColumnLink
                href={formatNubankContact(intl, 'mailto:', 'SUPORT_EMAIL')}
                intlKey="NUBANK.CONTACT.SUPORT_EMAIL"
              />
              <ColumnText
                color="white"
                colorVariant="dark"
                intlKey="FOOTER.CONTACT.BUSINESS_HOURS"
              />
            </Column>
            <Column>
              <ColumnTitle intlKey="FOOTER.APP.TITLE" />
              <Link
                href="https://itunes.apple.com/us/app/nubank/id814456780?ls=1&mt=8"
                intlAriaKey="FOOTER.APP.APPLE"
              >
                <AppStoreBadge platform="ios" />
              </Link>
              <StyledAppLink
                href="https://play.google.com/store/apps/details?id=com.nu.production"
                intlAriaKey="FOOTER.APP.ANDROID"
              >
                <AppStoreBadge platform="android" />
              </StyledAppLink>
            </Column>
          </>
        )}
      </Footer.Navigation>
      )}

      {company && (
      <Footer.Company data-testid="footer-company">
        {({
          CompanyInfo,
          Social,
        }) => (
          <>
            <NuLogo size="small" />

            {!insurance && (
              <CompanyInfo>
                <FormattedMessage id="NUBANK.COMPANY.YEAR" />
                &nbsp;&nbsp;
                <FormattedMessage id="NUBANK.COMPANY.NAME" />
                  &nbsp;&nbsp;
                <FormattedMessage id="NUBANK.COMPANY.CNPJ" />
                <br />
                <FormattedMessage id="NUBANK.ADDRESS.STREET" />
                  &nbsp;-&nbsp;
                <FormattedMessage id="NUBANK.ADDRESS.CITY_STATE" />
                  &nbsp;-&nbsp;
                <FormattedMessage id="NUBANK.ADDRESS.POSTAL_CODE" />
              </CompanyInfo>
            )}

            {insurance && (
              <StyledBox
                maxWidth={{ lg: '60rem' }}
              >
                <CompanyInfo>
                  <FormattedMessage id="NUBANK.COMPANY.YEAR" />
                  <br />
                  {formatMessage({ id: 'FOOTER.INSURANCE.STIPULATOR' })}
                  &nbsp;
                  {formatMessage({ id: 'NUBANK.COMPANY_PRODUCTS.NAME' })}
                  &nbsp;&nbsp;
                  {formatMessage({ id: 'NUBANK.COMPANY_PRODUCTS.CNPJ' })}
                  <br />
                  {formatMessage({ id: 'NUBANK.ADDRESS.STREET' })}
                  &nbsp;-&nbsp;
                  {formatMessage({ id: 'NUBANK.ADDRESS.CITY_STATE' })}
                  &nbsp;-&nbsp;
                  {formatMessage({ id: 'NUBANK.ADDRESS.POSTAL_CODE' })}
                  <br />
                  <br />
                </CompanyInfo>

                <CompanyInfo>
                  {formatMessage({ id: 'FOOTER.INSURANCE.INSURANCE_COMPANY' })}
                  &nbsp;
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.NAME' })}
                  &nbsp;&nbsp;
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.CNPJ' })}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  {formatMessage({ id: 'FOOTER.INSURANCE.SUSEP' })}
                  &nbsp;
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.SUSEP' })}
                  <br />
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.ADRESS.STREET' })}
                  &nbsp;-&nbsp;
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.ADRESS.CITY_STATE' })}
                  <br />
                  {formatMessage({ id: 'FOOTER.OMBUDSMAN.TITLE' })}
                  &nbsp;
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.OMBUDSMAN_PHONE' })}
                  &nbsp;-&nbsp;
                  {formatMessage({ id: 'FOOTER.INSURANCE.OMBUDSMAN_CONTACT_HOURS' })}
                  &nbsp;-&nbsp;
                  E-mail:
                  {' '}
                  {formatMessage({ id: 'NUBANK.INSURANCE_PARTNER.OMBUDSMAN_EMAIL' })}
                  <br />
                  <br />
                </CompanyInfo>

                <CompanyInfo>
                  {formatMessage({ id: 'FOOTER.INSURANCE.SUSEP_PROCESS' })}
                  <br />
                  {formatMessage({ id: 'FOOTER.INSURANCE.TERMS' })}
                  <br />
                  <br />
                </CompanyInfo>
                <CompanyInfo>
                  <Typography
                    tag="span"
                    variant="paragraph1"
                    strong
                    intlKey="INSURANCE.TERMS_AND_CONDITIONS"
                    intlValues={{
                      link: <Link
                        href={formatMessage({ id: 'INSURANCE.TERMS_AND_CONDITIONS.URL' })}
                        intlKey="INSURANCE.TERMS_AND_CONDITIONS.LINK"
                      />,
                    }}
                  />
                </CompanyInfo>
              </StyledBox>
            )}

            <Social>
              <StyledLink
                href={formatNubankSocialLink(intl, 'FACEBOOK')}
                intlAriaKey="FOOTER.SOCIAL_MEDIA.FACEBOOK"
              >
                <Icon color="white" name="facebook" />
              </StyledLink>
              <StyledLink
                href={formatNubankSocialLink(intl, 'INSTAGRAM')}
                intlAriaKey="FOOTER.SOCIAL_MEDIA.INSTAGRAM"
              >
                <Icon color="white" name="instagram" />
              </StyledLink>
              <StyledLink
                href={formatNubankSocialLink(intl, 'LINKEDIN')}
                intlAriaKey="FOOTER.SOCIAL_MEDIA.LINKEDIN"
              >
                <Icon color="white" name="linkedin" />
              </StyledLink>
              <StyledLink
                href={formatNubankSocialLink(intl, 'YOUTUBE')}
                intlAriaKey="FOOTER.SOCIAL_MEDIA.YOUTUBE"
              >
                <Icon color="white" name="youtube" />
              </StyledLink>
              <StyledLink
                href={formatNubankSocialLink(intl, 'TWITTER')}
                intlAriaKey="FOOTER.SOCIAL_MEDIA.TWITTER"
              >
                <Icon color="white" name="twitter" />
              </StyledLink>
            </Social>
          </>
        )}
      </Footer.Company>
      )}

      {languageSwitcher && (
      <Footer.Section compact data-testid="footer-language-switcher">
        <LanguageSwitcher route={pathname} />
      </Footer.Section>
      )}
    </Footer>
  );
}

FooterBrazil.propTypes = {
  company: PropTypes.bool,
  insurance: PropTypes.bool,
  languageSwitcher: PropTypes.bool,
  navigation: PropTypes.bool,
};

FooterBrazil.defaultProps = {
  company: true,
  languageSwitcher: true,
  navigation: true,
  insurance: false,
};

export default React.memo(FooterBrazil);
