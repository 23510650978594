import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';

import JumpToContent from '@nubank/www-latam-commons/components/JumpToContent/JumpToContent';
import DrawerToggleButton from 'patterns/DrawerToggleButton/DrawerToggleButton';
import SEO from 'components/SEO/SEO';
import Header from 'components/ui/Header/Header';
import HeaderWrapper from 'components/ui/HeaderWrapper/HeaderWrapper';
import Footer from 'components/ui/Footer/Footer';
import MenuWrapper from 'components/ui/MenuWrapper/MenuWrapper';
import { AnnouncementContextConsumer } from 'components/AnnouncementBar/AnnouncementBarProvider';
import SearchContextProvider, { SearchContext } from 'components/SearchScreen/SearchContext';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';

import { trackTimeOnPage } from './tracking';
import { Layout } from './styles/Layout';

const AnnouncementBar = dynamic(() => import('components/AnnouncementBar/AnnouncementBar'), { ssr: false });
const AnnouncementBarText = dynamic(() => import('components/AnnouncementBar/AnnouncementBar').then(mod => mod.default.Text), { ssr: false });
const AnnouncementBarCTA = dynamic(() => import('components/AnnouncementBar/AnnouncementBar').then(mod => mod.default.CTA), { ssr: false });
const SearchScreen = dynamic(() => import('components/SearchScreen/SearchScreen'), { ssr: false });

export const headerTypes = {
  COMPLETE: 'COMPLETE',
  NONE: 'NONE',
  EMPTY: 'EMPTY',
  LANDING_PAGE: 'LANDING_PAGE',
  PRODUCT_PAGE: 'PRODUCT_PAGE',
  FEATURE_PAGE: 'FEATURE_PAGE',
};

export const footerTypes = {
  FULL: 'FULL',
  COMPANY_INFO: 'COMPANY_INFO',
  NONE: 'NONE',
  INSURANCE: 'INSURANCE',
};

const footerLayouts = {
  FULL: {
    navigation: true,
    company: true,
    insurance: false,
    languageSwitcher: true,
  },
  COMPANY_INFO: {
    navigation: false,
    company: true,
    insurance: false,
    languageSwitcher: false,
  },
  INSURANCE: {
    navigation: true,
    company: true,
    insurance: true,
    languageSwitcher: true,
  },
};

export const headerLayouts = {
  COMPLETE: {
    container: true,
    navigation: true,
    onPageForm: false,
    login: true,
    waitScroll: false,
  },
  NONE: {
    container: false,
    navigation: false,
    login: false,
    onPageForm: false,
    waitScroll: false,
  },
  EMPTY: {
    container: true,
    navigation: false,
    onPageForm: false,
    login: false,
    waitScroll: false,
  },
  LANDING_PAGE: {
    container: true,
    navigation: false,
    onPageForm: true,
    login: false,
    waitScroll: true,
  },
  PRODUCT_PAGE: {
    container: true,
    navigation: true,
    onPageForm: true,
    login: true,
    waitScroll: false,
  },
  FEATURE_PAGE: {
    container: true,
    navigation: true,
    onPageForm: true,
    login: false,
    waitScroll: false,
  },
};

const websitePage = (options = {}) => {
  const hocOptions = {
    routeKey: 'HOME',
    header: headerTypes.COMPLETE,
    footer: footerTypes.FULL,
    ...options,
  };

  const sendTimeOnPageMetric = trackTimeOnPage();

  return WrappedComponent => {
    const WebsitePage = props => {
      const [isOnPageFormVisible, setIsOnPageFormVisible] = useState(true);
      const { locale } = useIntl();

      useEffect(() => () => sendTimeOnPageMetric(), []);

      const handleFormVisibilityChange = isFormHidden => {
        setIsOnPageFormVisible(!isFormHidden);
      };

      const {
        routeKey,
        footer,
        header,
      } = hocOptions;

      const {
        container: showHeader,
        navigation: allowNavigation,
        login: allowLogin,
        onPageForm: hasOnPageForm,
        waitScroll,
      } = headerLayouts[header];

      const showFooter = footer !== footerTypes.NONE;
      const headerVisibility = Boolean(waitScroll && isOnPageFormVisible);
      const handleFormVisibility = useCallback(
        isFormHidden => handleFormVisibilityChange(isFormHidden),
        [],
      );

      return (
        <SearchContextProvider>
          <SEO routeKey={routeKey} />

          <JumpToContent
            href="#main-content"
            intlKey="HEADER.JUMP_TO_CONTENT"
          />

          {showHeader && (!hasOnPageForm || (hasOnPageForm && !isOnPageFormVisible)) && (
            <DrawerToggleButton />
          )}

          {showHeader && (
            <HeaderWrapper
              isHidden={headerVisibility}
              aria-hidden={headerVisibility}
            >
              <Header isHidden={headerVisibility}>
                <MenuWrapper
                  showNavigation={allowNavigation}
                  showLogin={allowLogin}
                  isOnPageFormVisible={isOnPageFormVisible}
                  hasOnPageForm={hasOnPageForm}
                />
              </Header>
              <AnnouncementContextConsumer>
                {({
                  id,
                  isVisible,
                  handleClose: handleCloseAnnouncementBar,
                  handleClick: handleClickAnnouncementBar,
                  isAllowed,
                }) => (
                  <>
                    {isAllowed ? (
                      <AnnouncementBar
                        identifier={id}
                        initiallyVisible={isVisible}
                        onClose={handleCloseAnnouncementBar}
                      >
                        <AnnouncementBarText intlKey="NAVIGATION_UX_SURVEY.TEXT" />
                        <AnnouncementBarCTA
                          intlKey="NAVIGATION_UX_SURVEY.LINK"
                          href="https://nu.questionpro.com/t/APEUIZlJwI"
                          onClick={handleClickAnnouncementBar}
                        />
                      </AnnouncementBar>
                    ) : ''}
                  </>
                )}
              </AnnouncementContextConsumer>
            </HeaderWrapper>
          )}
          <Layout
            hasMenuHeightPadding={showHeader && !waitScroll}
          >
            <div id="main-content">
              <WrappedComponent
                {...props}
                onFormVisibilityChange={handleFormVisibility}
                isAnchorAllowed={hasOnPageForm}
              />
            </div>
            {showFooter && <Footer {...footerLayouts[footer]} />}
          </Layout>
          <SearchContext.Consumer>
            {({ isSearchOpen }) => (
              <>
                {locale === DEFAULT_LOCALE && isSearchOpen && <SearchScreen />}
              </>
            )}
          </SearchContext.Consumer>
        </SearchContextProvider>
      );
    };

    WebsitePage.getInitialProps = async ctx => {
      let pageProps = {};

      if (WrappedComponent.getInitialProps) {
        pageProps = await WrappedComponent.getInitialProps(ctx);
      }

      return {
        ...pageProps,
      };
    };

    return withRouter(WebsitePage);
  };
};

export default websitePage;
