import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { ALL_TYPES, MULTI_PRODUCT } from 'utils/prospectTypes';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';

export const ApplicationFormContext = React.createContext({
  isDrawerOpen: false,
  openDrawer: () => {
    console.error('Please wrap your component with ApplicationFormContextProvider before using this context.');
  },
  closeDrawer: () => {
    console.error('Please wrap your component with ApplicationFormContextProvider before using this context.');
  },
  toggleDrawer: () => {
    console.error('Please wrap your component with ApplicationFormContextProvider before using this context.');
  },
  initialField: undefined,
  initialFieldValue: undefined,
  customOptions: {},
  prospectTypeKey: MULTI_PRODUCT,
  initialTouched: {},
  hasApplicationFormDrawer: false,
});

export const useApplicationFormDrawerContext = () => React.useContext(ApplicationFormContext);

export default function ApplicationFormContextProvider({
  children,
  prospectTypeKey,
  initialField: _initialField,
  drawerInitialState,
}) {
  const { open } = drawerInitialState;
  const [isDrawerOpen, setDrawerOpen] = useState(open);
  const [initialField, setInitialField] = useState(_initialField);
  const [initialFieldValue, setInitialFieldValue] = useState('');
  const [customOptions, setCustomOptions] = useState({});
  const [initialTouched, setInitialTouched] = useState({});
  const { locale } = useIntl();

  const openDrawer = ({ initialField: field, initialFieldValue: fieldValue, customOptionsValue } = { initialField: _initialField, initialFieldValue: '', customOptionsValue: {} }) => {
    setInitialField(field);
    setInitialFieldValue(fieldValue);
    setCustomOptions(customOptionsValue);

    if (field && fieldValue) {
      setInitialTouched({ [field]: true });
    } else {
      setInitialTouched({});
    }
    setDrawerOpen(true);
  };
  const closeDrawer = () => setDrawerOpen(false);
  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  return (
    <ApplicationFormContext.Provider value={{
      isDrawerOpen,
      openDrawer,
      closeDrawer,
      toggleDrawer,
      initialField,
      initialFieldValue,
      customOptions,
      prospectTypeKey,
      initialTouched,
      hasApplicationFormDrawer: locale === DEFAULT_LOCALE,
    }}
    >
      {children}
    </ApplicationFormContext.Provider>
  );
}

ApplicationFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  drawerInitialState: PropTypes.shape({
    open: PropTypes.bool,
  }),
  initialField: PropTypes.string,
  prospectTypeKey: PropTypes.oneOf(ALL_TYPES).isRequired,
};

ApplicationFormContextProvider.defaultProps = {
  drawerInitialState: { open: false },
  initialField: undefined,
};

