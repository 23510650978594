import React from 'react';
import Button from '@nubank/nuds-web/components/Button/Button';

import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';
import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { trackOpenedWidget } from 'tracking/application/widget';
import { getProspectType } from 'utils/prospectTypes';

import { StickyContainer } from './style';

function DrawerToggleButton() {
  const {
    openDrawer,
    prospectTypeKey,
    isDrawerOpen,
    hasApplicationFormDrawer,
  } = useApplicationFormDrawerContext();
  const prospectType = getProspectType(prospectTypeKey);

  const handleOpenRegistrationForm = e => {
    trackOpenedWidget({
      prospectType: prospectType.type,
      buttonIndex: 2,
    });
    trackingClickMenu('open_widget');
    openDrawer();
    e.target.focus();
  };

  return hasApplicationFormDrawer ? (
    <StickyContainer>
      <Button
        id="widget-toggle-button"
        onClick={handleOpenRegistrationForm}
        extended
        aria-expanded={isDrawerOpen}
        intlKey={prospectType.buttonLabel}
      />
    </StickyContainer>
  ) : null;
}

export default DrawerToggleButton;
