import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Box from '@nubank/nuds-web/components/Box/Box';
import { useBreakpointsMediaDown, useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

import Logo from './patterns/Logo/Logo';
import Actions from './patterns/Actions/Actions';
import ActionsSmallScreen from './patterns/ActionsSmallScreen/ActionsSmallScreen';
import { MenuContext } from './MenuContext';
import Navigation, { NavigationKeyToComponentMap } from './patterns/Navigation/Navigation';
import ButtonToggle from './components/ButtonToggle/ButtonToggle';
import {
  normalizeNavigationStructure,
  typeValidation,
  getChildrenByType,
  normalizeNavigationStructureComponents,
} from './utils';
import NavigationListSmallScreen from './patterns/Navigation/NavigationListSmallScreen/NavigationListSmallScreen';

const NavigationListLargeScreen = dynamic(
  () => import('./patterns/Navigation/NavigationListLargeScreen/NavigationListLargeScreen'),
  {
    loading: () => <Box width="100%" height="100%" backgroundColor="white.default" />,
    ssr: false,
  },
);

function Menu({
  children,
  className,
  mobileButtonIconTitle,
  onToggleMobileMenu,
  hideMobileMenu,
  currentRoutePath: routePath,
}) {
  const logoChildren = getChildrenByType(children, ['Logo']);
  const actionsChildren = getChildrenByType(children, ['Actions']);
  const actionsSmallScreenChildren = getChildrenByType(children, ['ActionsSmallScreen']);
  const [navigationChildren] = getChildrenByType(children, ['Navigation']);
  const navigationComponentStructure = navigationChildren
    ? normalizeNavigationStructureComponents(navigationChildren
      .props
      .children(NavigationKeyToComponentMap)
      .props
      .children)
    : [];

  const menuContext = useContext(MenuContext);
  const menuWrapperRef = useRef(null);
  const menuNavigationStructure = normalizeNavigationStructure(navigationComponentStructure);
  const [navigationSectionActive, setNavigationSectionActive] = useState('');
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(menuContext.isMobileNavOpen);
  const [currentRoutePath, currentsetRoutePath] = useState(routePath);
  const [isServer] = useState(typeof window === 'undefined');

  const toggleMobileMenu = () => {
    setIsMobileNavOpen(prevValue => {
      onToggleMobileMenu(!prevValue);
      return !prevValue;
    });
  };

  useEffect(() => {
    if (routePath) {
      currentsetRoutePath(routePath);
    }
  }, [routePath]);

  const breakpointsDownLG = isServer ? true : useBreakpointsMediaDown('lg');
  const breakpointsUpLG = isServer ? false : useBreakpointsMediaUp('lg');

  return (
    <MenuContext.Provider
      value={{
        navigationSectionActive,
        setNavigationSectionActive,
        menuNavigationStructure,
        isMobileNavOpen,
        setIsMobileNavOpen,
        currentRoutePath,
        currentsetRoutePath,
        toggleMobileMenu,
      }}
    >
      {navigationChildren}
      <Box
        ref={menuWrapperRef}
        className={className}
        width="100%"
        position="relative"
        boxShadow={{
          xs: `0px 1px 1px ${nuDSColor('black', 'defaultT10')()}`,
          lg: 'none',
        }}
      >
        <Box
          width="100%"
          height={{
            xs: '64px',
            lg: '56px',
          }}
          paddingLeft={{
            xs: '6x',
            md: '8x',
            lg: '12x',
          }}
          paddingRight={{
            xs: '10px',
            md: '18px',
            lg: '12x',
          }}
          display="grid"
          gridTemplateColumns="min-content minmax(160px,1fr) minmax(min-content, auto)"
          gridGap={{
            xs: '2x',
            lg: '8x',
          }}
          alignItems="stretch"
          backgroundColor="white.default"
          position="relative"
          zIndex={{
            xs: '2',
            lg: 'auto',
          }}
        >
          <Box
            width="auto"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {logoChildren}
          </Box>

          {breakpointsUpLG && (
            <NavigationListLargeScreen componentsList={navigationComponentStructure} />
          )}

          <Box>{actionsChildren}</Box>

          {(!hideMobileMenu && breakpointsDownLG) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonToggle
                onMenuToggleClick={toggleMobileMenu}
                isMobileNavOpen={isMobileNavOpen}
                iconProps={mobileButtonIconTitle}
              />
            </Box>
          )}
        </Box>

        {breakpointsDownLG && (
          <NavigationListSmallScreen
            listNavigationComponents={navigationComponentStructure}
            actionsSmallScreenChildren={actionsSmallScreenChildren}
            isHidden={!isMobileNavOpen}
          />
        )}

      </Box>
    </MenuContext.Provider>
  );
}

Menu.Logo = Logo;
Menu.Actions = Actions;
Menu.ActionsSmallScreen = ActionsSmallScreen;
Menu.Navigation = Navigation;

Menu.defaultProps = {
  className: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  __TYPE: 'Menu',
  hideMobileMenu: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  onToggleMobileMenu: () => {},
  currentRoutePath: undefined,
};

Menu.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Menu'),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  currentRoutePath: PropTypes.string,
  hideMobileMenu: PropTypes.bool,
  mobileButtonIconTitle: PropTypes.shape({
    titleClose: PropTypes.string,
    titleOpen: PropTypes.string,
  }).isRequired,
  onToggleMobileMenu: PropTypes.func,
};

export default Menu;
