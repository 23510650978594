import PropTypes from 'prop-types';

export const CREDIT_CARD = 'CREDIT_CARD';
export const NU_CONTA = 'NU_CONTA';
export const DEBIT = 'DEBIT';
export const MULTI_PRODUCT = 'MULTI_PRODUCT';
export const COMPANY_NU_CONTA = 'COMPANY_NU_CONTA';
export const COMPANY_NU_CONTA_WAITLIST = 'COMPANY_NU_CONTA_WAITLIST';
export const BUSINESS_CREDIT_CARD = 'BUSINESS_CREDIT_CARD';
export const ULTRAVIOLET = 'ULTRAVIOLET';

export const ALL_TYPES = [
  CREDIT_CARD, NU_CONTA, DEBIT, MULTI_PRODUCT, COMPANY_NU_CONTA, BUSINESS_CREDIT_CARD, ULTRAVIOLET,
];

export const ProspectTypeTypes = PropTypes.oneOf(['credit_card', 'savings', 'debit', 'company_savings', 'global']);

export const ProspectTypeShape = PropTypes.shape({
  buttonLabel: PropTypes.string.isRequired,
  discoveryFields: PropTypes.shape({
    inviter: PropTypes.oneOf(['register_prospect', 'register_prospect_savings', 'register_prospect_debit', 'register_prospect_c', 'register_prospect_global']).isRequired,
    unsolicited: PropTypes.oneOf(['register_prospect', 'register_prospect_savings_web', 'register_prospect_debit', 'register_prospect_c', 'register_prospect_global_web', 'register_prospect_ultraviolet_web']).isRequired,
  }),
  formTitle: PropTypes.string.isRequired,
  type: ProspectTypeTypes.isRequired,
});

function createProspectType({
  buttonLabel, formTitle, type, unsolicitedField, inviterField,
}) {
  return {
    buttonLabel,
    formTitle,
    type,
    discoveryFields: {
      inviter: inviterField || unsolicitedField,
      unsolicited: unsolicitedField,
    },
  };
}

const prospectTypes = {
  [CREDIT_CARD]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.CREDIT_CARD.BUTTON',
    formTitle: 'COMMON.WIDGET.CREDIT_CARD.TITLE',
    type: 'credit_card',
    unsolicitedField: 'register_prospect',
  }),
  [NU_CONTA]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.NU_CONTA.BUTTON',
    formTitle: 'COMMON.WIDGET.NU_CONTA.TITLE',
    type: 'savings',
    unsolicitedField: 'register_prospect_savings_web',
    inviterField: 'register_prospect_savings',
  }),
  [DEBIT]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.DEBIT.BUTTON',
    formTitle: 'COMMON.WIDGET.DEBIT.TITLE',
    type: 'debit',
    unsolicitedField: 'register_prospect_debit',
  }),
  [MULTI_PRODUCT]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.MULTI_PRODUCT.BUTTON',
    formTitle: 'COMMON.WIDGET.MULTI_PRODUCT.TITLE',
    type: 'global',
    unsolicitedField: 'register_prospect_global_web',
    inviterField: 'register_prospect_global',
  }),
  [COMPANY_NU_CONTA]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.COMPANY_NU_CONTA.BUTTON',
    formTitle: 'COMMON.WIDGET.COMPANY_NU_CONTA.TITLE',
    type: 'company_savings',
    unsolicitedField: 'register_prospect_c',
  }),
  [COMPANY_NU_CONTA_WAITLIST]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.COMPANY_NU_CONTA.WAITLIST_BUTTON',
    formTitle: 'COMMON.WIDGET.COMPANY_NU_CONTA.TITLE_WAITLIST',
    type: 'company_savings',
    unsolicitedField: 'register_prospect_c',
  }),
  [BUSINESS_CREDIT_CARD]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.BUSINESS_CREDIT_CARD.BUTTON',
    type: 'company_savings',
  }),
  [ULTRAVIOLET]: createProspectType({
    buttonLabel: 'COMMON.WIDGET.ULTRAVIOLET.BUTTON',
    type: 'ultravioleta',
    unsolicitedField: 'register_prospect_ultraviolet_web',
    inviterField: 'register_prospect_ultraviolet',
  }),
};

export function getProspectType(prospectTypeKey = '') {
  return prospectTypes[prospectTypeKey];
}

export function isCompanyProspectType(prospectType) {
  const { type } = prospectType;

  return type === prospectTypes[COMPANY_NU_CONTA].type;
}

export function accumulateProspectType(currProspectType, newProspectType) {
  const creditCard = prospectTypes[CREDIT_CARD].type;
  const conta = prospectTypes[NU_CONTA].type;
  const multiProduct = prospectTypes[MULTI_PRODUCT].type;

  const isAccumulative = currProspectType !== newProspectType
    && [creditCard, conta].includes(currProspectType)
    && [creditCard, conta, multiProduct].includes(newProspectType);

  return isAccumulative ? multiProduct : currProspectType;
}
