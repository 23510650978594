import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Link from '@nubank/nuds-web/components/Link/Link';
import NuLogo from '@nubank/nuds-web/components/NuLogo/NuLogo';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import { useIntl } from 'react-intl';

import Menu from 'components/Menu/Menu';
import RouterLink from 'components/RouterLink/RouterLink';
import { trackingClickMenu } from 'tracking/menu/clickInteraction';
import { DEFAULT_LOCALE } from 'utils/i18nUtils';
import Experiment from '@nubank/www-latam-commons/components/Experiment/Experiment';
import SearchButton from 'components/Menu/patterns/Navigation/SearchButton';

import { SEARCH_MVP } from '../../../utils/experiments';

import ApplicationButton from './components/ApplicationButton/ApplicationButton';
import { ActionsWrapper } from './styles/ActionsWrapper';
import { StyledChip } from './styles/StyledChip';
import { LogoWrapper } from './styles/LogoWrapper';

function MenuWrapper({
  showNavigation,
  showLogin,
  isOnPageFormVisible,
  hasOnPageForm,

}) {
  const router = useRouter();
  const { route } = router;
  const { formatMessage } = useContext(NuDsContext);
  const [isMenuMobileNavOpen, setMenuMobileNavOpen] = useState(false);
  const { locale } = useIntl();
  const isBrazil = locale === DEFAULT_LOCALE;

  const isRouteChipHighligthed = routePath => (
    routePath === route
      ? 'primary.default'
      : 'black.light'
  );

  const breakpointsUpLG = useBreakpointsMediaUp('lg');

  const toggleMobileMenu = isMenuMobileOpen => {
    const eventLabel = isMenuMobileOpen ? 'open_menu' : 'close_menu';
    setMenuMobileNavOpen(isMenuMobileOpen);
    trackingClickMenu(eventLabel);
  };

  const Logo = () => <NuLogo variant="primary" size="small" />;

  return (
    <>
      <Menu
        currentRoutePath={route}
        hideMobileMenu={!showNavigation}
        onToggleMobileMenu={isMenuMobileOpen => toggleMobileMenu(isMenuMobileOpen)}
        mobileButtonIconTitle={{
          titleClose: formatMessage({ id: 'COMMON.MENU.CLOSE_ACTION.TITLE' }),
          titleOpen: formatMessage({ id: 'COMMON.MENU.OPEN_ACTION.TITLE' }),
        }}
      >
        <Menu.Logo>
          {showNavigation ? (
            <LogoWrapper>
              <RouterLink
                href="/"
                onClick={() => trackingClickMenu('logo')}
              >
                <Logo />
              </RouterLink>
            </LogoWrapper>
          ) : (
            <Logo />
          )}
        </Menu.Logo>
        <Menu.Actions>
          <ActionsWrapper
            height="100%"
            width="100%"
            minWidth="100px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Experiment id={SEARCH_MVP.id}>
              <Experiment.Variant id={SEARCH_MVP.variantA}>
                {(isBrazil && showNavigation && (isMenuMobileNavOpen || breakpointsUpLG)) && (
                  <SearchButton />
                )}
              </Experiment.Variant>
            </Experiment>
            {(showLogin && breakpointsUpLG) && (
              <Link
                iconProps={{ name: 'login' }}
                href="https://app.nubank.com.br/"
                intlKey="HEADER.LOGIN.BUTTON"
                variant="action"
                onClick={() => trackingClickMenu('navigate_to_login')}
              />
            )}
            {(!hasOnPageForm || (hasOnPageForm && !isOnPageFormVisible)) && (
              <ApplicationButton />
            )}
          </ActionsWrapper>
        </Menu.Actions>
        <Menu.ActionsSmallScreen>
          {({
            ActionLink,
          }) => (
            <>
              {showLogin && (
                <>
                  <ActionLink
                    iconProps={{
                      name: 'login',
                      title: 'login',
                    }}
                    href="https://app.nubank.com.br/"
                    intlKey="HEADER.LOGIN.BUTTON"
                    variant="action"
                    onClick={() => trackingClickMenu('navigate_to_login')}
                  />
                </>
              )}
            </>
          )}
        </Menu.ActionsSmallScreen>
        {showNavigation && (
          <Menu.Navigation>
            {({
              Section,
              SectionTitle,
              SectionLink,
              Column,
              ColumnTitle,
              ColumnLink,
            }) => (
              <>
                <Section>
                  <SectionLink
                    href="/"
                    intlKey="HEADER.MENU.HOME"
                    onClick={() => trackingClickMenu('navigate_to_home')}
                  />
                </Section>
                <Section>
                  <SectionTitle intlKey="HEADER.MENU.FOR_YOU" />
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.FOR_YOU.NUBANK" />
                    <ColumnLink
                      href="/cartao"
                      intlKey="HEADER.MENU.FOR_YOU.CREDIT_CARD"
                      onClick={() => trackingClickMenu('navigate_to_credit_card')}
                    />
                    <ColumnLink
                      href="/conta"
                      onClick={() => trackingClickMenu('navigate_to_nuconta')}
                      intlKey="HEADER.MENU.FOR_YOU.NUCONTA"
                    />
                    <ColumnLink
                      href="/pix"
                      intlKey="HEADER.MENU.FOR_YOU.PIX"
                      forceRefresh
                      onClick={() => trackingClickMenu('navigate_to_pix')}
                    />
                  </Column>
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.FOR_YOU.ULTRAVIOLET" />
                    <ColumnLink
                      href="/ultravioleta"
                      intlKey="HEADER.MENU.FOR_YOU.ULTRAVIOLET_CARD"
                      onClick={() => trackingClickMenu('navigate_to_ultraviolet')}
                      afterComponent={() => (
                        <StyledChip
                          backgroundColor="primary.defaultL10"
                          textColor={isRouteChipHighligthed('/ultravioleta')}
                          text={formatMessage({ id: 'HEADER.MENU.FOR_YOU.ULTRAVIOLET.LABEL' })}
                        />
                      )}
                    />
                  </Column>
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.FOR_YOU.OTHER_PRODUCTS" />
                    <ColumnLink
                      href="/emprestimo"
                      intlKey="HEADER.MENU.FOR_YOU.LENDING"
                      onClick={() => trackingClickMenu('navigate_to_lending')}
                    />
                    <ColumnLink
                      href="/seguros"
                      intlKey="HEADER.MENU.FOR_YOU.INSURANCE"
                      onClick={() => trackingClickMenu('navigate_to_insurance')}
                    />
                    <ColumnLink
                      href="/investimentos"
                      intlKey="HEADER.MENU.FOR_YOU.INVESTMENTS"
                      onClick={() => trackingClickMenu('navigate_to_investments')}
                      afterComponent={() => (
                        <StyledChip
                          backgroundColor="primary.defaultL10"
                          textColor={isRouteChipHighligthed('/investimentos')}
                          text={formatMessage({ id: 'HEADER.MENU.FOR_YOU.INVESTMENTS.LABEL' })}
                        />
                      )}
                    />
                  </Column>
                </Section>
                <Section>
                  <SectionTitle intlKey="HEADER.MENU.FOR_YOUR_BUSINESS" />
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.FOR_YOUR_BUSINESS.NUBANK_BUSINESS" />
                    <ColumnLink
                      href="/conta-pj"
                      intlKey="HEADER.MENU.FOR_YOUR_BUSINESS.ACCOUNT"
                      onClick={() => trackingClickMenu('navigate_to_business_account')}
                    />
                    <ColumnLink
                      href="/cartao-pj"
                      intlKey="HEADER.MENU.FOR_YOUR_BUSINESS.CREDIT_CARD"
                      onClick={() => trackingClickMenu('navigate_to_business_credit_card')}
                      afterComponent={() => (
                        <StyledChip
                          backgroundColor="primary.defaultL10"
                          textColor={isRouteChipHighligthed('/cartao-pj')}
                          text={formatMessage({ id: 'HEADER.MENU.FOR_YOUR_BUSINESS.CREDIT_CARD.LABEL' })}
                        />
                      )}
                    />
                  </Column>
                </Section>
                <Section>
                  <SectionTitle intlKey="HEADER.MENU.THE_NUBANK" />
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.THE_NUBANK.ABOUT_US" />
                    <ColumnLink
                      href="/sobre-nos"
                      intlKey="HEADER.MENU.THE_NUBANK.THE_NU"
                      onClick={() => trackingClickMenu('navigate_to_about_us')}
                    />
                    <ColumnLink
                      href="/imprensa"
                      intlKey="HEADER.MENU.THE_NUBANK.PRESS"
                      onClick={() => trackingClickMenu('navigate_to_press')}
                    />
                    <ColumnLink
                      href="/carreiras"
                      intlKey="HEADER.MENU.THE_NUBANK.CAREERS"
                      onClick={() => trackingClickMenu('navigate_to_careers')}
                    />
                    <ColumnLink
                      href={formatMessage({ id: 'ESG.EXTERNAL_URL' })}
                      intlKey="HEADER.MENU.THE_NUBANK.ESG"
                      onClick={() => trackingClickMenu('navigate_to_blog_social_impact')}
                    />
                  </Column>
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.THE_NUBANK.OTHER_CHANNELS" />
                    <ColumnLink
                      href="https://blog.nubank.com.br/"
                      intlKey="HEADER.MENU.THE_NUBANK.BLOG"
                      onClick={() => trackingClickMenu('navigate_to_blog')}
                    />
                    <ColumnLink
                      href="https://blog.nubank.com.br/assinar-newsletter/?utm_content=www_header-nlcta"
                      intlKey="HEADER.MENU.THE_NUBANK.NEWSLETTER"
                      onClick={() => trackingClickMenu('navigate_to_newsletter')}
                    />
                    <ColumnLink
                      href="https://comunidade.nubank.com.br/?utm_content=www_hero"
                      intlKey="HEADER.MENU.THE_NUBANK.NUCOMMUNITY"
                      onClick={() => trackingClickMenu('navigate_to_nucommunity')}
                    />
                    <ColumnLink
                      href="https://investnews.com.br?utm_source=nubank.com.br&utm_medium=referral&utm_campaign=nu-visitors&utm_content=header"
                      intlKey="HEADER.MENU.THE_NUBANK.INVEST_NEWS"
                      onClick={() => trackingClickMenu('navigate_to_invest_news')}
                    />
                  </Column>
                  <Column>
                    <ColumnTitle intlKey="HEADER.MENU.THE_NUBANK.NUBANK_AROUND_WORLD" />
                    <ColumnLink
                      href="https://nu.com.mx/"
                      intlKey="HEADER.MENU.THE_NUBANK.MX"
                      onClick={() => trackingClickMenu('navigate_to_mx')}
                    />
                    <ColumnLink
                      href="https://nu.com.co/"
                      intlKey="HEADER.MENU.THE_NUBANK.CO"
                      onClick={() => trackingClickMenu('navigate_to_co')}
                    />
                    <ColumnLink
                      href="https://nu.com.ar/"
                      intlKey="HEADER.MENU.THE_NUBANK.AR"
                      onClick={() => trackingClickMenu('navigate_to_ar')}
                    />
                  </Column>
                </Section>
                <Section>
                  <SectionLink
                    href="/perguntas"
                    intlKey="HEADER.MENU.FAQ"
                    onClick={() => trackingClickMenu('navigate_to_faq')}
                  />
                </Section>
              </>
            )}
          </Menu.Navigation>
        )}
      </Menu>
    </>
  );
}

MenuWrapper.defaultProps = {
  hasOnPageForm: false,
  isOnPageFormVisible: false,
  showLogin: false,
  showNavigation: false,
};

MenuWrapper.propTypes = {
  hasOnPageForm: PropTypes.bool,
  isOnPageFormVisible: PropTypes.bool,
  showLogin: PropTypes.bool,
  showNavigation: PropTypes.bool,
};

export default React.memo(MenuWrapper);
