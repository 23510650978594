import styled, { css } from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledApplicationButton = styled(Button)`
  display: none;
  flex-shrink: 0;
  margin-left: 1rem;
  height: 2.5rem;

  span {
    font-size: 1rem;
    line-height: 125%;
    letter-spacing: -0.01em;
  } 

  ${breakpointsMedia({
    md: css`
      display: flex;
    `,
  })}
`;

StyledApplicationButton.displayName = 'StyledApplicationButton';
